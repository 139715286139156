.advantage {
    text-align: center;
}
.advantage .img_wrap {
    margin-bottom: 20px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #555555;
}
.text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #555555;
}

@media (max-width: 1200px) {
    .title {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 993px) {
    .title {
        height: unset;
    }
}

@media (max-width: 768px) {
    .title {
        height: 46px;
        line-height: 23px;
    }
}

@media (max-width: 576px) {
    .title {
        font-size: 13px;
        height: 40px;
        line-height: 16px;
    }
    .text {
        font-size: 12px;
        line-height: 18px;
    }
    .advantage .img_wrap {
        margin: 0;
    }
}
