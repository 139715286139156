.menu {
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
.menu li {
    margin: 0 10px;
}
.menu li a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #555555;
    text-decoration: none !important;
    transition: 0.3s;
}
.menu li a:hover {
    color: #1b92c9;
}

@media (max-width: 1200px) {
    .menu {
        padding-left: 0;
        justify-content: center;
    }
    .menu li {
        margin: 0 9px;
    }
    .menu li a {
        font-size: 12px;
    }
}
