.footer {
    padding: 70px 0 50px;
    background: #134c5f;
}
.map_wrap iframe {
    width: 100%;
    height: 300px;
}
.footer_info_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer_left_info {
    width: 55%;
}
.footer_right_info {
    width: 45%;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /*text-align: center;*/
    letter-spacing: 0.02em;
    color: #45b7fc;
    margin-top: 20px;
    margin-bottom: 10px;
}
.socials a {
    margin-right: 20px;
}
.questions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
}
.questions p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #f9ffff;
    margin: 0;
}
.questions a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    padding: 14px 26px;
    text-decoration: none !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    transition: 0.3s;
}
.questions a:hover {
    color: #555555 !important;
    background: #f3f680;
}
.wg {
    padding-top: 40px;
    text-align: center;
}
.wg a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}
.wg a span {
    /* font-family: "Post No Bills Colombo"; */
    font-weight: 700;
}

@media (max-width: 1200px) {
    .questions p {
        font-size: 21px;
    }
}

@media (max-width: 993px) {
    .map_wrap {
        order: 3;
        padding-top: 20px;
    }
    .questions {
        display: none;
    }
}

@media (max-width: 576px) {
    .footer {
        padding: 30px 0 0 0;
    }
    .map_wrap {
        padding: 20px 0 0 0 !important;
    }
    .footer_info_wrap {
        flex-direction: column;
    }
    .footer_info_wrap > div {
        width: 100%;
        text-align: center;
    }
    .footer_info_wrap > div > div {
        width: fit-content;
        margin: 10px auto;
    }
    .wg {
        padding-top: 20px;
    }
    .title {
        margin-top: 0;
    }
    .address {
        max-width: 247px;
    }
}
