.price {
    width: 100%;
    position: relative;
    z-index: 2;
}
.white,
.blue {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #555555;
}
.white span,
.blue span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #1b92c9;
}
.white {
    background: #E4F0FF;
    margin-bottom: 5px;

}
.blue {
    background: #E4F0FF;
    margin-bottom: 5px;
}

@media (max-width: 576px) {
    div,
    span {
        font-size: 15px !important;
    }
}
