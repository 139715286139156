.image_wrap {
    height: 215px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #555555;
    text-align: center;
}
.text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #555555;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .image_wrap img {
        width: 100%;
    }
    .title {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 993px) {
    .image_wrap img {
        width: unset;
    }
    .image_wrap {
        justify-content: center;
    }
    .text {
        margin-bottom: 30px;
    }
    .title {
        height: unset;
    }
}

@media (max-width: 576px) {
    .title {
        font-size: 20px;
        line-height: 24px;
    }
    .text {
        font-size: 14px;
        line-height: 21px;
    }
}
