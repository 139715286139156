.header {
    padding: 20px 0;
    position: fixed;
    width: 100%;
    z-index: 6;
    background: #fff;
    top: 0;
}
.header_phone a {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #1b92c9;
    text-decoration: none !important;
}
.header_phone {
    position: relative;
    display: flex;
    align-items: center;
}
.hidden_phones {
    display: none;
    position: absolute;
    text-align: center;
    left: 33px;
    margin-top: -5px;
    background: #fff;
    padding: 5px 10px;
}
.phone_icon {
    position: relative;
    z-index: 1;
}
.hidden_phones a {
    display: block;
}
.header_phone > span:hover .hidden_phones {
    display: block;
}
.header_phone > span:hover .arrow_down {
    transform: rotate(180deg);
}

.mobile-toggle,
.mobile-logo,
.mobile_phone {
    display: none;
}

@media (max-width: 1200px) {
    .logo_wrap {
        display: flex;
        align-items: center;
    }
    .logo_wrap a img,
    .logo_wrap a {
        width: 100%;
    }
    .header_phone a {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .logo_wrap,
    .desktop_menu_wrap,
    .header_phone {
        display: none;
    }
    .mobile-toggle,
    .mobile-logo,
    .mobile_phone {
        display: block;
        text-align: center;
    }
    .menu_responsive ul li a {
        font-size: 30px;
    }
}

@media (max-width: 576px) {
    .menu_responsive ul li a {
        font-size: 23px;
    }
    .menu_responsive ul li {
        padding: 0 !important;
    }
}

@media (max-width: 400px) {
    .mobile_phone {
        padding-left: 0 !important;
    }
    .mobile-toggle {
        padding-right: 0 !important;
    }
    .menu_responsive ul li a {
        font-size: 16px;
    }
    .menu_responsive ul li {
        font-size: 16px !important;
        padding: 5px 0 !important;
    }
}

/* =============================== MOBILE MENU ======================================== */
.button_menu {
    width: 40px;
    height: 40px;
    padding: 8px 5px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    z-index: 3;
    margin: 0 auto;
}
.button_menu span {
    background: #7cacc6;
    width: 100%;
    height: 4px;
    transition: opacity 300ms ease, transform 300ms ease;
}
.button_menu.active span {
    position: absolute;
    width: 100%;
    height: 6px;
    top: 43%;
    background: #7cacc6;
}
.button_menu.active span:first-child {
    transform: rotate(45deg);
}
.button_menu.active span:last-child {
    transform: rotate(-45deg);
}
.button_menu.active span:nth-child(2) {
    opacity: 0;
}
.main_menu {
    display: flex;
    justify-content: center;
    padding: 21px 46px;
}
.main_menu ul {
    display: flex;
    flex-direction: column;
}
.main_menu ul li {
    margin-right: 25px;
    font-weight: 300;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    transform: perspective(1px) translateZ(0);
}
.menu_responsive {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    width: 75%;
    z-index: 2;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    justify-content: flex-end;
}
.menu_responsive .main_menu {
    padding: 0px 40px 0;
    display: flex;
    background: #e7f1f4;
    width: 100%;
    flex-direction: column;
    overflow: scroll;
}
.menu_responsive .main_menu ul {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: auto;
    list-style: none;
    margin: 0;
}
.menu_responsive .main_menu li {
    margin: 0 0 10px;
    font-size: 30px;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 500;
}
.menu_responsive.active {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
}

/* ==================================================================================== */
