.section4 {
    padding: 40px 0 60px;
}
.title {
    display: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.05em;

    color: #555555;
    margin-bottom: 30px;
}

@media (max-width: 993px) {
    .title {
        display: block;
    }
}

@media (max-width: 576px) {
    .section4 {
        padding: 20px 0 0;
    }
    .title {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
}
