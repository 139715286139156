.sidebar {
    position: fixed;
    right: 0;
    bottom: 150px;
    z-index: 10;
}
.sidebar a {
    display: block;
    margin-bottom: 30px;
}
.sidebar a img {
    border-radius: 5px;
}

@media (max-width: 576px) {
    .sidebar a img {
        width: 30px;
    }
    .sidebar a {
        margin-bottom: 20px;
    }
}
