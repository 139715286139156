.subtitle {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    margin: 0;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 0.05em;
    color: #555555;
    margin: 0;
    text-align: center;
}
.subsubtitle {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 0.05em;
    color: #555555;
    margin: 0 0 10px 0;
    text-align: center;
}
.inputs_wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
}
.inputs_wrap div label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #555555;
    display: block;
}
.required {
    color: #1b92c9;
}
.inputs_wrap > div {
    width: 47%;
}
.inputs_wrap div input {
    width: 100%;
    height: 50px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-left: 25px;
    border: none !important;
    outline: none !important;
}
.inputs_wrap div input::placeholder {
    color: rgba(85, 85, 85, 0.5);
}
.buttons_wrap {
    text-align: center;
}
.buttons_wrap button {
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
    padding: 12px 50px;
}
.error {
    font-family: Montserrat;
    font-weight: bold;
    font-style: italic;
    color: red;
}
.success {
    font-family: Montserrat;
    font-weight: bold;
    font-style: italic;
    color: green;
    text-align: center;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .inputs_wrap {
        flex-direction: column;
        padding: 0 15px;
    }
    .inputs_wrap > div {
        width: 100%;
        margin: 10px 0;
    }
    .subtitle {
        text-align: center;
    }
}

@media (max-width: 576px) {
    .subtitle {
        font-size: 16px;
    }
    .title {
        font-size: 26px;
        line-height: 35px;
    }
    .subsubtitle {
        font-size: 18px;
        line-height: 20px;
    }
    .inputs_wrap div label {
        font-size: 16px;
    }
    .buttons_wrap button {
        font-size: 14px;
    }
}
