.section6 {
    padding: 60px 0 120px;
}
.discount_left {
    display: flex;
    align-items: flex-end;
}

.discount_left div {
    background: #1b92c9;
    padding: 40px 15px 40px 20px;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-bottom: 5px;
}
.subtitle {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #73ccf4;
    margin-bottom: 25px;
}
.text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    margin: 0;
}
.discount_right img {
    width: 100%;
    box-shadow: 0px 4px 30px #d1d8e0;
}
.discount_right .title {
    display: none;
    text-align: center;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #555555;
}

@media (max-width: 1200px) {
    .subtitle {
        line-height: 20px;
    }
}

@media (max-width: 993px) {
    .discount_left {
        display: none;
    }
    .discount_right .title {
        display: block;
    }
}

@media (max-width: 576px) {
    .section6 {
        padding: 30px 0;
    }
    .discount_right .title {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .discount_right {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
