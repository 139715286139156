.info_line {
    margin-bottom: 10px;
}

.info_line,
.info_line a {
    display: flex;
    align-items: center;
}
.info_line img,
.info_line a img {
    margin-right: 5px;
}
.info_line p,
.info_line a p {
    margin: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
}

@media (max-width: 576px) {
    .info_line,
    .info_line a {
        /* flex-direction: column; */
    }
    .info_line img,
    .info_line a img {
        /* margin-bottom: 10px; */
    }
}
