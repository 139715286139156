.section7 {
    padding: 70px 0 40px;
    background: #f6faf9;
}
.subtitle {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    color: rgba(27, 146, 201, 0.5);
    margin: 0;
    padding-top: 65px;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
    letter-spacing: 0.03em;
    color: #555555;
    margin-bottom: 45px;
}
.link {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    padding: 14px 30px;
    text-decoration: none !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    transition: 0.3s;
}
.link:hover {
    color: #555555 !important;
    background: #f3f680;
}
.inst_wrap {
    /* height: 326px; */
}
.inst_wrap > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
/* .inst_wrap > div > a {
    width: 15%;
    height: auto;
    margin-bottom: 15px;
    position: relative;
    transition: 0.3s;
} */
/* .inst_wrap > div > a:hover::after {
    opacity: 1;
}
.inst_wrap > div > a::after {
    content: url("../../assets/img/inst.svg");
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    background: linear-gradient(
        180deg,
        rgba(90, 90, 90, 0.7) 0%,
        rgba(38, 38, 38, 0.7) 100%
    );
    opacity: 0;
    transition: 0.3s;
}
.inst_wrap > div > a > img {
    width: 100%;
} */

.inst_info_wrap {
    text-align: center;
}

@media (max-width: 1200px) {
    .title {
        font-size: 19px;
    }
    .inst_wrap {
        /* height: 272px; */
    }
}

@media (max-width: 993px) {
    .subtitle {
        padding-top: 0;
    }
    .sec7_wrap {
        flex-direction: column-reverse;
    }
    .inst_wrap {
        margin-top: 60px;
        /* height: 346px; */
    }
}

@media (max-width: 768px) {
    .inst_wrap > div > a {
        width: 32%;
    }
}

@media (max-width: 576px) {
    .section7 {
        padding: 30px 0 0;
    }
    .link {
        font-size: 14px;
    }
}
