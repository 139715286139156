.section3 {
    padding-bottom: 40px;
}
.left img {
    width: 100%;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 0.05em;
    color: #555555;
    padding-top: 60px;
    margin-bottom: 10px;
}
.subtitle {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 32px;
    color: rgba(27, 146, 201, 0.5);
    margin-bottom: 30px;
}
.text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #555555;
    margin-bottom: 45px;
}
.button {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    text-decoration: none !important;
    padding: 12px 30px;
    border: none;
    transition: 0.3s;
}
.button:hover {
    color: #555555 !important;
    background: #f3f680;
}

@media (max-width: 993px) {
    .left img {
        display: none;
    }
    .title,
    .subtitle,
    .text {
        text-align: center;
    }
    .title {
        padding-top: 0;
        margin-bottom: 30px;
    }
    .text {
        margin-bottom: 20px;
    }
    .subtitle {
        margin-bottom: 15px;
    }
    .button {
        margin: 0 auto;
        display: block;
        width: fit-content;
    }
}

@media (max-width: 576px) {
    .section3 {
        padding-bottom: 20px;
    }
    .title {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .subtitle {
        font-size: 18px;
    }
    .text {
        font-size: 12px;
        line-height: 18px;
    }
    .button {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .subtitle {
        display: none;
    }
}
