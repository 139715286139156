.section5 {
    padding: 60px 0;
    position: relative;
}
.price_info {
    display: flex;
    align-items: center;
}

.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0.05em;
    color: #555555;
    margin: 0;
}
.subtitle {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    color: rgba(27, 146, 201, 0.5);
    margin-bottom: 60px;
}
.label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #555555;
    margin-bottom: 15px;
}
.select {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #555555;
    width: 100%;
    height: 40px;
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(91, 91, 91, 0.25);
    border: none !important;
    margin-bottom: 20px;
    outline: none;
}
.wrap {
    margin-bottom: 20px;
}
.link {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    text-decoration: none !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    padding: 14px 45px;
    transition: 0.3s;
}
.link:hover {
    color: #555555 !important;
    background: #f3f680;
}
.bubbles {
    position: absolute;
    right: 0;
    z-index: 1;
}
.non_breed {
    position: relative;
    box-shadow: 0px 4px 30px #d1d8e0;
}
.animals {
    position: absolute;
    right: 0;
    bottom: -17px;
    z-index: 2;
}
.price_bg {
    position: absolute;
    left: 0;
    top: -40px;
    z-index: 0;
}
.breed_col {
    display: flex;
    align-items: center;
}
.mobile_non_breed {
    display: none;
}

@media (max-width: 1400px) {
    .bubbles {
        display: none;
    }
}

@media (max-width: 1200px) {
    .non_breed > img:first-child {
        width: 100%;
    }
    .animals {
        width: 335px;
    }
}

@media (max-width: 993px) {
    .price_info {
        width: 100%;
        margin-bottom: 60px;
    }
    .title {
        text-align: center;
        margin-bottom: 30px;
    }
    .label {
        width: 100%;
        text-align: center;
    }
    .price_info > div {
        width: 100%;
    }
    .subtitle {
        display: none;
    }
    .link {
        display: none;
    }
    .select {
        margin-bottom: 0;
    }
    .price_bg {
        display: none;
    }
    .mobile_non_breed {
        display: block;
    }
    .breed_select_img,
    .animals {
        display: none;
    }
    .non_breed {
        margin: 0 auto;
    }
}

@media (max-width: 576px) {
    .section5 {
        padding: 0;
    }
    .price_info {
        margin-bottom: 20px;
    }
    .title {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .non_breed img {
        width: 100%;
    }
    .breed_col {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
