.section1 {
    position: relative;
    min-height: 800px;
}
.left_section {
    padding-top: 175px;
}

.subtitle {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    color: #8dc9e4;
}
.title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: 0.05em;
    color: #555555;
    margin-bottom: 42px;
    position: relative;
    z-index: 2;
}
.text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #818181;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
}
.text span {
    font-style: italic;
}
.price {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: 0.05em;
    color: #1b92c9;
    margin-bottom: 42px;
}
.price span {
    font-size: 36px;
}
.button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #ffffff;
    border: none;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    padding: 16px 14px;
    transition: 0.3s;
}
.button:hover {
    color: #555555;
    background: #f3f680;
}
.sec1_image {
    position: absolute;
    right: 60px;
    top: 90px;
    z-index: 1;
}
.mobile_image {
    display: none;
}

@media (max-width: 1740px) {
    .title {
        max-width: 80%;
    }
    .text {
        max-width: 55%;
    }
}

@media (max-width: 1500px) {
    .sec1_image {
        width: 800px;
    }
}
@media (max-width: 1350px) {
    .title {
        font-size: 41px;
        line-height: 42px;
    }
}

@media (max-width: 1200px) {
    .sec1_image {
        width: 650px;
        top: 165px;
    }
    .title {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 25px;
    }
    .text {
        font-size: 21px;
    }
    .subtitle {
        font-size: 31px;
    }
    .price {
        font-size: 40px;
    }
    .price span {
        font-size: 30px;
    }
    .button {
        font-size: 16px;
        padding: 12px 14px;
    }
    .section1 {
        min-height: 750px;
    }
}

@media (max-width: 993px) {
    .title,
    .text,
    .subtitle,
    .price {
        max-width: unset;
        text-align: center;
    }
    .button {
        margin: 0 auto;
        display: block;
    }
    .sec1_image {
        display: none;
    }
    .section1 {
        background: url("../../assets/img/sec1_tablet_bg.jpg") no-repeat;
        background-position: bottom center;
        background-size: contain;
        margin-top: 100px;
    }
    .left_section {
        padding-top: 0;
    }
    .mobile_image {
        display: block;
        width: 100%;
    }
    .price {
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .section1 {
        background-size: cover;
        background-position: center;
    }
}

@media (max-width: 576px) {
    .section1 {
        min-height: unset;
    }
    .subtitle {
        font-size: 18px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    .title {
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 15px;
    }
    .text {
        font-size: 18px;
        line-height: 28px;
        color: #1b92c9;
    }
    .price {
        font-size: 36px;
        line-height: 26px;
    }
    .button {
        font-size: 14px;
    }
}
