.modal_wrap {
    padding: 60px 128px;
    background: url("../../assets/img/form_bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.modal_wrap > div {
    min-width: 735px;
}
.close {
    position: absolute;
    right: 5px;
    top: 5px;
}
.close img {
    width: 30px;
    height: 30px;
}

@media (max-width: 993px) {
    .modal_wrap {
        width: 100%;
        padding: 60px 0;
    }
    .modal_wrap > div {
        width: 750px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .modal_wrap > div {
        width: 100%;
        min-width: unset;
    }
}

@media (max-width: 576px) {
    .modal_wrap {
        padding: 30px 0;
    }
}
