.elf_instagram_feed .eapps-instagram-feed-title-container {
    display: none;
}
.elf_instagram_feed .eapps-instagram-feed-posts-grid-load-more-container {
    display: none !important;
}
.elf_instagram_feed .inst_wrap > div > a.eapps-link {
    display: none !important;
    height: 1px !important;
    width: 0px !important;
}
.elf_instagram_feed a.eapps-link {
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px) !important;
}
