@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Marck+Script&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.elf_instagram_feed .eapps-instagram-feed-title-container {
    display: none;
}
.elf_instagram_feed .eapps-instagram-feed-posts-grid-load-more-container {
    display: none !important;
}
.elf_instagram_feed .inst_wrap > div > a.eapps-link {
    display: none !important;
    height: 1px !important;
    width: 0px !important;
}
.elf_instagram_feed a.eapps-link {
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px) !important;
            clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px) !important;
}

.styles_menu__1FxVm {
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
.styles_menu__1FxVm li {
    margin: 0 10px;
}
.styles_menu__1FxVm li a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #555555;
    text-decoration: none !important;
    transition: 0.3s;
}
.styles_menu__1FxVm li a:hover {
    color: #1b92c9;
}

@media (max-width: 1200px) {
    .styles_menu__1FxVm {
        padding-left: 0;
        justify-content: center;
    }
    .styles_menu__1FxVm li {
        margin: 0 9px;
    }
    .styles_menu__1FxVm li a {
        font-size: 12px;
    }
}

.styles_header__3rVC6 {
    padding: 20px 0;
    position: fixed;
    width: 100%;
    z-index: 6;
    background: #fff;
    top: 0;
}
.styles_header_phone__QC6yo a {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #1b92c9;
    text-decoration: none !important;
}
.styles_header_phone__QC6yo {
    position: relative;
    display: flex;
    align-items: center;
}
.styles_hidden_phones__1DPoe {
    display: none;
    position: absolute;
    text-align: center;
    left: 33px;
    margin-top: -5px;
    background: #fff;
    padding: 5px 10px;
}
.styles_phone_icon__2V-_V {
    position: relative;
    z-index: 1;
}
.styles_hidden_phones__1DPoe a {
    display: block;
}
.styles_header_phone__QC6yo > span:hover .styles_hidden_phones__1DPoe {
    display: block;
}
.styles_header_phone__QC6yo > span:hover .styles_arrow_down__2Q-n8 {
    transform: rotate(180deg);
}

.styles_mobile-toggle__3db28,
.styles_mobile-logo__1xde9,
.styles_mobile_phone__1NlgQ {
    display: none;
}

@media (max-width: 1200px) {
    .styles_logo_wrap__Oyjn9 {
        display: flex;
        align-items: center;
    }
    .styles_logo_wrap__Oyjn9 a img,
    .styles_logo_wrap__Oyjn9 a {
        width: 100%;
    }
    .styles_header_phone__QC6yo a {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .styles_logo_wrap__Oyjn9,
    .styles_desktop_menu_wrap__BqMx0,
    .styles_header_phone__QC6yo {
        display: none;
    }
    .styles_mobile-toggle__3db28,
    .styles_mobile-logo__1xde9,
    .styles_mobile_phone__1NlgQ {
        display: block;
        text-align: center;
    }
    .styles_menu_responsive__1FzwP ul li a {
        font-size: 30px;
    }
}

@media (max-width: 576px) {
    .styles_menu_responsive__1FzwP ul li a {
        font-size: 23px;
    }
    .styles_menu_responsive__1FzwP ul li {
        padding: 0 !important;
    }
}

@media (max-width: 400px) {
    .styles_mobile_phone__1NlgQ {
        padding-left: 0 !important;
    }
    .styles_mobile-toggle__3db28 {
        padding-right: 0 !important;
    }
    .styles_menu_responsive__1FzwP ul li a {
        font-size: 16px;
    }
    .styles_menu_responsive__1FzwP ul li {
        font-size: 16px !important;
        padding: 5px 0 !important;
    }
}

/* =============================== MOBILE MENU ======================================== */
.styles_button_menu__1XOAo {
    width: 40px;
    height: 40px;
    padding: 8px 5px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    z-index: 3;
    margin: 0 auto;
}
.styles_button_menu__1XOAo span {
    background: #7cacc6;
    width: 100%;
    height: 4px;
    transition: opacity 300ms ease, transform 300ms ease;
}
.styles_button_menu__1XOAo.styles_active__2VHgX span {
    position: absolute;
    width: 100%;
    height: 6px;
    top: 43%;
    background: #7cacc6;
}
.styles_button_menu__1XOAo.styles_active__2VHgX span:first-child {
    transform: rotate(45deg);
}
.styles_button_menu__1XOAo.styles_active__2VHgX span:last-child {
    transform: rotate(-45deg);
}
.styles_button_menu__1XOAo.styles_active__2VHgX span:nth-child(2) {
    opacity: 0;
}
.styles_main_menu__rePq2 {
    display: flex;
    justify-content: center;
    padding: 21px 46px;
}
.styles_main_menu__rePq2 ul {
    display: flex;
    flex-direction: column;
}
.styles_main_menu__rePq2 ul li {
    margin-right: 25px;
    font-weight: 300;
    font-size: 15px;
    cursor: pointer;
    position: relative;
    transform: perspective(1px) translateZ(0);
}
.styles_menu_responsive__1FzwP {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    width: 75%;
    z-index: 2;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    justify-content: flex-end;
}
.styles_menu_responsive__1FzwP .styles_main_menu__rePq2 {
    padding: 0px 40px 0;
    display: flex;
    background: #e7f1f4;
    width: 100%;
    flex-direction: column;
    overflow: scroll;
}
.styles_menu_responsive__1FzwP .styles_main_menu__rePq2 ul {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: auto;
    list-style: none;
    margin: 0;
}
.styles_menu_responsive__1FzwP .styles_main_menu__rePq2 li {
    margin: 0 0 10px;
    font-size: 30px;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 500;
}
.styles_menu_responsive__1FzwP.styles_active__2VHgX {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
}

/* ==================================================================================== */

.styles_section1__2FPft {
    position: relative;
    min-height: 800px;
}
.styles_left_section__3GaNt {
    padding-top: 175px;
}

.styles_subtitle__6aiJF {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    color: #8dc9e4;
}
.styles_title__gN1RH {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: 0.05em;
    color: #555555;
    margin-bottom: 42px;
    position: relative;
    z-index: 2;
}
.styles_text__1f9Eg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #818181;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
}
.styles_text__1f9Eg span {
    font-style: italic;
}
.styles_price__1aEqm {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: 0.05em;
    color: #1b92c9;
    margin-bottom: 42px;
}
.styles_price__1aEqm span {
    font-size: 36px;
}
.styles_button__1Caeb {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #ffffff;
    border: none;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    padding: 16px 14px;
    transition: 0.3s;
}
.styles_button__1Caeb:hover {
    color: #555555;
    background: #f3f680;
}
.styles_sec1_image__jA1Ju {
    position: absolute;
    right: 60px;
    top: 90px;
    z-index: 1;
}
.styles_mobile_image__31CGK {
    display: none;
}

@media (max-width: 1740px) {
    .styles_title__gN1RH {
        max-width: 80%;
    }
    .styles_text__1f9Eg {
        max-width: 55%;
    }
}

@media (max-width: 1500px) {
    .styles_sec1_image__jA1Ju {
        width: 800px;
    }
}
@media (max-width: 1350px) {
    .styles_title__gN1RH {
        font-size: 41px;
        line-height: 42px;
    }
}

@media (max-width: 1200px) {
    .styles_sec1_image__jA1Ju {
        width: 650px;
        top: 165px;
    }
    .styles_title__gN1RH {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 25px;
    }
    .styles_text__1f9Eg {
        font-size: 21px;
    }
    .styles_subtitle__6aiJF {
        font-size: 31px;
    }
    .styles_price__1aEqm {
        font-size: 40px;
    }
    .styles_price__1aEqm span {
        font-size: 30px;
    }
    .styles_button__1Caeb {
        font-size: 16px;
        padding: 12px 14px;
    }
    .styles_section1__2FPft {
        min-height: 750px;
    }
}

@media (max-width: 993px) {
    .styles_title__gN1RH,
    .styles_text__1f9Eg,
    .styles_subtitle__6aiJF,
    .styles_price__1aEqm {
        max-width: unset;
        text-align: center;
    }
    .styles_button__1Caeb {
        margin: 0 auto;
        display: block;
    }
    .styles_sec1_image__jA1Ju {
        display: none;
    }
    .styles_section1__2FPft {
        background: url(/static/media/sec1_tablet_bg.2a721669.jpg) no-repeat;
        background-position: bottom center;
        background-size: contain;
        margin-top: 100px;
    }
    .styles_left_section__3GaNt {
        padding-top: 0;
    }
    .styles_mobile_image__31CGK {
        display: block;
        width: 100%;
    }
    .styles_price__1aEqm {
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .styles_section1__2FPft {
        background-size: cover;
        background-position: center;
    }
}

@media (max-width: 576px) {
    .styles_section1__2FPft {
        min-height: unset;
    }
    .styles_subtitle__6aiJF {
        font-size: 18px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    .styles_title__gN1RH {
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 15px;
    }
    .styles_text__1f9Eg {
        font-size: 18px;
        line-height: 28px;
        color: #1b92c9;
    }
    .styles_price__1aEqm {
        font-size: 36px;
        line-height: 26px;
    }
    .styles_button__1Caeb {
        font-size: 14px;
    }
}

.styles_section2__2jTF0 {
    padding: 40px 0;
}

.styles_advantage__3oCdx {
    text-align: center;
}
.styles_advantage__3oCdx .styles_img_wrap__1q-bv {
    margin-bottom: 20px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.styles_title__32X2L {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #555555;
}
.styles_text__2yY64 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #555555;
}

@media (max-width: 1200px) {
    .styles_title__32X2L {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 993px) {
    .styles_title__32X2L {
        height: unset;
    }
}

@media (max-width: 768px) {
    .styles_title__32X2L {
        height: 46px;
        line-height: 23px;
    }
}

@media (max-width: 576px) {
    .styles_title__32X2L {
        font-size: 13px;
        height: 40px;
        line-height: 16px;
    }
    .styles_text__2yY64 {
        font-size: 12px;
        line-height: 18px;
    }
    .styles_advantage__3oCdx .styles_img_wrap__1q-bv {
        margin: 0;
    }
}

.styles_section3__1ywUH {
    padding-bottom: 40px;
}
.styles_left__3Rej- img {
    width: 100%;
}
.styles_title__3FRdP {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 0.05em;
    color: #555555;
    padding-top: 60px;
    margin-bottom: 10px;
}
.styles_subtitle__3FTTG {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 32px;
    color: rgba(27, 146, 201, 0.5);
    margin-bottom: 30px;
}
.styles_text__1_bOJ {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #555555;
    margin-bottom: 45px;
}
.styles_button__Yz2Xv {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    text-decoration: none !important;
    padding: 12px 30px;
    border: none;
    transition: 0.3s;
}
.styles_button__Yz2Xv:hover {
    color: #555555 !important;
    background: #f3f680;
}

@media (max-width: 993px) {
    .styles_left__3Rej- img {
        display: none;
    }
    .styles_title__3FRdP,
    .styles_subtitle__3FTTG,
    .styles_text__1_bOJ {
        text-align: center;
    }
    .styles_title__3FRdP {
        padding-top: 0;
        margin-bottom: 30px;
    }
    .styles_text__1_bOJ {
        margin-bottom: 20px;
    }
    .styles_subtitle__3FTTG {
        margin-bottom: 15px;
    }
    .styles_button__Yz2Xv {
        margin: 0 auto;
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media (max-width: 576px) {
    .styles_section3__1ywUH {
        padding-bottom: 20px;
    }
    .styles_title__3FRdP {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .styles_subtitle__3FTTG {
        font-size: 18px;
    }
    .styles_text__1_bOJ {
        font-size: 12px;
        line-height: 18px;
    }
    .styles_button__Yz2Xv {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .styles_subtitle__3FTTG {
        display: none;
    }
}

.styles_section4__24o9m {
    padding: 40px 0 60px;
}
.styles_title__3dDSJ {
    display: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.05em;

    color: #555555;
    margin-bottom: 30px;
}

@media (max-width: 993px) {
    .styles_title__3dDSJ {
        display: block;
    }
}

@media (max-width: 576px) {
    .styles_section4__24o9m {
        padding: 20px 0 0;
    }
    .styles_title__3dDSJ {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
}

.styles_image_wrap__2DgRA {
    height: 215px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.styles_title__1uZyo {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #555555;
    text-align: center;
}
.styles_text__2uTYQ {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #555555;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .styles_image_wrap__2DgRA img {
        width: 100%;
    }
    .styles_title__1uZyo {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 993px) {
    .styles_image_wrap__2DgRA img {
        width: unset;
    }
    .styles_image_wrap__2DgRA {
        justify-content: center;
    }
    .styles_text__2uTYQ {
        margin-bottom: 30px;
    }
    .styles_title__1uZyo {
        height: unset;
    }
}

@media (max-width: 576px) {
    .styles_title__1uZyo {
        font-size: 20px;
        line-height: 24px;
    }
    .styles_text__2uTYQ {
        font-size: 14px;
        line-height: 21px;
    }
}

.styles_section5__8glHx {
    padding: 60px 0;
    position: relative;
}
.styles_price_info__2OgqS {
    display: flex;
    align-items: center;
}

.styles_title__3Qqjg {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0.05em;
    color: #555555;
    margin: 0;
}
.styles_subtitle__2mtQr {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    color: rgba(27, 146, 201, 0.5);
    margin-bottom: 60px;
}
.styles_label__2tkWN {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #555555;
    margin-bottom: 15px;
}
.styles_select__1rmV_ {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #555555;
    width: 100%;
    height: 40px;
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(91, 91, 91, 0.25);
    border: none !important;
    margin-bottom: 20px;
    outline: none;
}
.styles_wrap__1IueX {
    margin-bottom: 20px;
}
.styles_link__1OoEz {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    text-decoration: none !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    padding: 14px 45px;
    transition: 0.3s;
}
.styles_link__1OoEz:hover {
    color: #555555 !important;
    background: #f3f680;
}
.styles_bubbles__2lNdb {
    position: absolute;
    right: 0;
    z-index: 1;
}
.styles_non_breed__1X976 {
    position: relative;
    box-shadow: 0px 4px 30px #d1d8e0;
}
.styles_animals__2QVx8 {
    position: absolute;
    right: 0;
    bottom: -17px;
    z-index: 2;
}
.styles_price_bg__28sEO {
    position: absolute;
    left: 0;
    top: -40px;
    z-index: 0;
}
.styles_breed_col__2hDFT {
    display: flex;
    align-items: center;
}
.styles_mobile_non_breed__1uNZn {
    display: none;
}

@media (max-width: 1400px) {
    .styles_bubbles__2lNdb {
        display: none;
    }
}

@media (max-width: 1200px) {
    .styles_non_breed__1X976 > img:first-child {
        width: 100%;
    }
    .styles_animals__2QVx8 {
        width: 335px;
    }
}

@media (max-width: 993px) {
    .styles_price_info__2OgqS {
        width: 100%;
        margin-bottom: 60px;
    }
    .styles_title__3Qqjg {
        text-align: center;
        margin-bottom: 30px;
    }
    .styles_label__2tkWN {
        width: 100%;
        text-align: center;
    }
    .styles_price_info__2OgqS > div {
        width: 100%;
    }
    .styles_subtitle__2mtQr {
        display: none;
    }
    .styles_link__1OoEz {
        display: none;
    }
    .styles_select__1rmV_ {
        margin-bottom: 0;
    }
    .styles_price_bg__28sEO {
        display: none;
    }
    .styles_mobile_non_breed__1uNZn {
        display: block;
    }
    .styles_breed_select_img__1aGlb,
    .styles_animals__2QVx8 {
        display: none;
    }
    .styles_non_breed__1X976 {
        margin: 0 auto;
    }
}

@media (max-width: 576px) {
    .styles_section5__8glHx {
        padding: 0;
    }
    .styles_price_info__2OgqS {
        margin-bottom: 20px;
    }
    .styles_title__3Qqjg {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .styles_non_breed__1X976 img {
        width: 100%;
    }
    .styles_breed_col__2hDFT {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.styles_price__23MBV {
    width: 100%;
    position: relative;
    z-index: 2;
}
.styles_white__tQvO8,
.styles_blue__2gJA0 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #555555;
}
.styles_white__tQvO8 span,
.styles_blue__2gJA0 span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #1b92c9;
}
.styles_white__tQvO8 {
    background: #E4F0FF;
    margin-bottom: 5px;

}
.styles_blue__2gJA0 {
    background: #E4F0FF;
    margin-bottom: 5px;
}

@media (max-width: 576px) {
    div,
    span {
        font-size: 15px !important;
    }
}

.styles_section6__1ltl- {
    padding: 60px 0 120px;
}
.styles_discount_left__2g3of {
    display: flex;
    align-items: flex-end;
}

.styles_discount_left__2g3of div {
    background: #1b92c9;
    padding: 40px 15px 40px 20px;
}
.styles_title__2zS47 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-bottom: 5px;
}
.styles_subtitle__3p7Tc {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #73ccf4;
    margin-bottom: 25px;
}
.styles_text__1L8LQ {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    margin: 0;
}
.styles_discount_right__2asL- img {
    width: 100%;
    box-shadow: 0px 4px 30px #d1d8e0;
}
.styles_discount_right__2asL- .styles_title__2zS47 {
    display: none;
    text-align: center;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #555555;
}

@media (max-width: 1200px) {
    .styles_subtitle__3p7Tc {
        line-height: 20px;
    }
}

@media (max-width: 993px) {
    .styles_discount_left__2g3of {
        display: none;
    }
    .styles_discount_right__2asL- .styles_title__2zS47 {
        display: block;
    }
}

@media (max-width: 576px) {
    .styles_section6__1ltl- {
        padding: 30px 0;
    }
    .styles_discount_right__2asL- .styles_title__2zS47 {
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
    .styles_discount_right__2asL- {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.styles_section7__sy1A3 {
    padding: 70px 0 40px;
    background: #f6faf9;
}
.styles_subtitle__2VtMm {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 34px;
    color: rgba(27, 146, 201, 0.5);
    margin: 0;
    padding-top: 65px;
}
.styles_title__o0c0f {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
    letter-spacing: 0.03em;
    color: #555555;
    margin-bottom: 45px;
}
.styles_link__3MNVf {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    padding: 14px 30px;
    text-decoration: none !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    transition: 0.3s;
}
.styles_link__3MNVf:hover {
    color: #555555 !important;
    background: #f3f680;
}
.styles_inst_wrap__1iX4d {
    /* height: 326px; */
}
.styles_inst_wrap__1iX4d > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
/* .inst_wrap > div > a {
    width: 15%;
    height: auto;
    margin-bottom: 15px;
    position: relative;
    transition: 0.3s;
} */
/* .inst_wrap > div > a:hover::after {
    opacity: 1;
}
.inst_wrap > div > a::after {
    content: url("../../assets/img/inst.svg");
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    background: linear-gradient(
        180deg,
        rgba(90, 90, 90, 0.7) 0%,
        rgba(38, 38, 38, 0.7) 100%
    );
    opacity: 0;
    transition: 0.3s;
}
.inst_wrap > div > a > img {
    width: 100%;
} */

.styles_inst_info_wrap__3rXxW {
    text-align: center;
}

@media (max-width: 1200px) {
    .styles_title__o0c0f {
        font-size: 19px;
    }
    .styles_inst_wrap__1iX4d {
        /* height: 272px; */
    }
}

@media (max-width: 993px) {
    .styles_subtitle__2VtMm {
        padding-top: 0;
    }
    .styles_sec7_wrap__1rFfF {
        flex-direction: column-reverse;
    }
    .styles_inst_wrap__1iX4d {
        margin-top: 60px;
        /* height: 346px; */
    }
}

@media (max-width: 768px) {
    .styles_inst_wrap__1iX4d > div > a {
        width: 32%;
    }
}

@media (max-width: 576px) {
    .styles_section7__sy1A3 {
        padding: 30px 0 0;
    }
    .styles_link__3MNVf {
        font-size: 14px;
    }
}

.styles_footer__hENza {
    padding: 70px 0 50px;
    background: #134c5f;
}
.styles_map_wrap__3oje8 iframe {
    width: 100%;
    height: 300px;
}
.styles_footer_info_wrap__22bRz {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.styles_footer_left_info__3CSB6 {
    width: 55%;
}
.styles_footer_right_info__1eqrY {
    width: 45%;
}
.styles_title__2rHFt {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /*text-align: center;*/
    letter-spacing: 0.02em;
    color: #45b7fc;
    margin-top: 20px;
    margin-bottom: 10px;
}
.styles_socials__22dho a {
    margin-right: 20px;
}
.styles_questions__2bL7Q {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
}
.styles_questions__2bL7Q p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #f9ffff;
    margin: 0;
}
.styles_questions__2bL7Q a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff !important;
    padding: 14px 26px;
    text-decoration: none !important;
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    transition: 0.3s;
}
.styles_questions__2bL7Q a:hover {
    color: #555555 !important;
    background: #f3f680;
}
.styles_wg__1IPeh {
    padding-top: 40px;
    text-align: center;
}
.styles_wg__1IPeh a {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}
.styles_wg__1IPeh a span {
    /* font-family: "Post No Bills Colombo"; */
    font-weight: 700;
}

@media (max-width: 1200px) {
    .styles_questions__2bL7Q p {
        font-size: 21px;
    }
}

@media (max-width: 993px) {
    .styles_map_wrap__3oje8 {
        order: 3;
        padding-top: 20px;
    }
    .styles_questions__2bL7Q {
        display: none;
    }
}

@media (max-width: 576px) {
    .styles_footer__hENza {
        padding: 30px 0 0 0;
    }
    .styles_map_wrap__3oje8 {
        padding: 20px 0 0 0 !important;
    }
    .styles_footer_info_wrap__22bRz {
        flex-direction: column;
    }
    .styles_footer_info_wrap__22bRz > div {
        width: 100%;
        text-align: center;
    }
    .styles_footer_info_wrap__22bRz > div > div {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 10px auto;
    }
    .styles_wg__1IPeh {
        padding-top: 20px;
    }
    .styles_title__2rHFt {
        margin-top: 0;
    }
    .styles_address__I9Yq8 {
        max-width: 247px;
    }
}

.styles_info_line__2oz-V {
    margin-bottom: 10px;
}

.styles_info_line__2oz-V,
.styles_info_line__2oz-V a {
    display: flex;
    align-items: center;
}
.styles_info_line__2oz-V img,
.styles_info_line__2oz-V a img {
    margin-right: 5px;
}
.styles_info_line__2oz-V p,
.styles_info_line__2oz-V a p {
    margin: 0px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
}

@media (max-width: 576px) {
    .styles_info_line__2oz-V,
    .styles_info_line__2oz-V a {
        /* flex-direction: column; */
    }
    .styles_info_line__2oz-V img,
    .styles_info_line__2oz-V a img {
        /* margin-bottom: 10px; */
    }
}

.styles_sidebar__GkA77 {
    position: fixed;
    right: 0;
    bottom: 150px;
    z-index: 10;
}
.styles_sidebar__GkA77 a {
    display: block;
    margin-bottom: 30px;
}
.styles_sidebar__GkA77 a img {
    border-radius: 5px;
}

@media (max-width: 576px) {
    .styles_sidebar__GkA77 a img {
        width: 30px;
    }
    .styles_sidebar__GkA77 a {
        margin-bottom: 20px;
    }
}

.styles_modal_wrap__3XCPH {
    padding: 60px 128px;
    background: url(/static/media/form_bg.4b22d19f.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.styles_modal_wrap__3XCPH > div {
    min-width: 735px;
}
.styles_close__6RXZo {
    position: absolute;
    right: 5px;
    top: 5px;
}
.styles_close__6RXZo img {
    width: 30px;
    height: 30px;
}

@media (max-width: 993px) {
    .styles_modal_wrap__3XCPH {
        width: 100%;
        padding: 60px 0;
    }
    .styles_modal_wrap__3XCPH > div {
        width: 750px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .styles_modal_wrap__3XCPH > div {
        width: 100%;
        min-width: unset;
    }
}

@media (max-width: 576px) {
    .styles_modal_wrap__3XCPH {
        padding: 30px 0;
    }
}

.styles_wrapper__5PcIB {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 11;
}

.styles_subtitle__1-tNS {
    font-family: "Marck Script";
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    margin: 0;
}
.styles_title__1FG5f {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 0.05em;
    color: #555555;
    margin: 0;
    text-align: center;
}
.styles_subsubtitle__PGyMT {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 0.05em;
    color: #555555;
    margin: 0 0 10px 0;
    text-align: center;
}
.styles_inputs_wrap__1R4VM {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
}
.styles_inputs_wrap__1R4VM div label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #555555;
    display: block;
}
.styles_required__1R8P6 {
    color: #1b92c9;
}
.styles_inputs_wrap__1R4VM > div {
    width: 47%;
}
.styles_inputs_wrap__1R4VM div input {
    width: 100%;
    height: 50px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-left: 25px;
    border: none !important;
    outline: none !important;
}
.styles_inputs_wrap__1R4VM div input::-webkit-input-placeholder {
    color: rgba(85, 85, 85, 0.5);
}
.styles_inputs_wrap__1R4VM div input:-ms-input-placeholder {
    color: rgba(85, 85, 85, 0.5);
}
.styles_inputs_wrap__1R4VM div input::-ms-input-placeholder {
    color: rgba(85, 85, 85, 0.5);
}
.styles_inputs_wrap__1R4VM div input::placeholder {
    color: rgba(85, 85, 85, 0.5);
}
.styles_buttons_wrap__3CNkN {
    text-align: center;
}
.styles_buttons_wrap__3CNkN button {
    background: #1b92c9;
    box-shadow: 0px 4px 8px rgba(0, 62, 90, 0.4);
    border-radius: 5px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
    padding: 12px 50px;
}
.styles_error__2nc87 {
    font-family: Montserrat;
    font-weight: bold;
    font-style: italic;
    color: red;
}
.styles_success__1t8_T {
    font-family: Montserrat;
    font-weight: bold;
    font-style: italic;
    color: green;
    text-align: center;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .styles_inputs_wrap__1R4VM {
        flex-direction: column;
        padding: 0 15px;
    }
    .styles_inputs_wrap__1R4VM > div {
        width: 100%;
        margin: 10px 0;
    }
    .styles_subtitle__1-tNS {
        text-align: center;
    }
}

@media (max-width: 576px) {
    .styles_subtitle__1-tNS {
        font-size: 16px;
    }
    .styles_title__1FG5f {
        font-size: 26px;
        line-height: 35px;
    }
    .styles_subsubtitle__PGyMT {
        font-size: 18px;
        line-height: 20px;
    }
    .styles_inputs_wrap__1R4VM div label {
        font-size: 16px;
    }
    .styles_buttons_wrap__3CNkN button {
        font-size: 14px;
    }
}

